@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url(assets/icons/css/flag-icon.css); */
/* @import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gentium+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  html {
    /* font-family: "Lato", system-ui, sans-serif; */
    /* font-family: "Nunito Sans", sans-serif; */
    /* font-family: "Poppins", sans-serif; */
    /* font-family: "Inter", sans-serif; */
    font-family: "Fira Sans", sans-serif;
    /* background-color: #f4f5fa; */
    background-color: #fff;
    color: #475f7b !important;
  }
  .app {
    height: 100vh;
    background: #f4f5fa;
  }
  .header-text {
    font-family: "Gentium Plus", serif;
  }
  .num {
    font-family: "Lato", system-ui, sans-serif !important;
  }

  .analytics-num {
    font-family: "Comfortaa", cursive !important;
  }

  .iconShadow {
    filter: drop-shadow(4px 4px 1px rgba(0, 0, 0, 0.3));
  }

  .iconShadow-alt {
    filter: drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.3));
  }

  .nav-link {
    font-family: "Comfortaa", cursive !important;
  }
}

.HW_badge_cont {
  position: absolute !important;
  top: -15px;
  left: 15px;
  display: block;
}

.bg-hexagons-dark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23000000' fill-opacity='0.03' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.rmdp-range {
  background-color: rgb(241 245 249);
  color: #475f7b;
}

.rmdp-today span,
.rmdp-day span:hover,
.rmdp-arrow-container:hover {
  background-color: #388951 !important;
}

.rmdp-week-day {
  color: #388951 !important;
}

.rmdp-arrow {
  border-color: #388951;
}

.firebase-emulator-warning {
  display: none;
}
